import React, { useState, useRef } from "react";
import "./MultipleChoice.css"

var key = require("./key.json")

interface Props {
    choices: string[]
    currentQuiz: number;
    submitQuiz(currentQuiz: number, myAnswer: string | string[], theAnswer: string[]): void;
}



export const MultipleChoice: React.FC<Props> = ({ choices, currentQuiz, submitQuiz }) => {

    const theAnswer: string[] = key['key'][currentQuiz.toString()]["answer"]

    return (

        <div className="btn-container">

            {choices.map((choice) => {
                return (

                    <button
                        className="btn btn-blue"
                        onClick={() => {
                            submitQuiz(currentQuiz, choice, theAnswer)
                        }}>
                        {choice}
                    </button>

                )
            }
            )
            }




        </div>

    );
};


export default MultipleChoice;