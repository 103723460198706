const players = require('./player_careers.json') as Player_Stats[];

// Define interface for player data
interface Player_Stats {
    full_name: string;
    id: string;
    retired: boolean;
    career_hits: number;
    career_home_runs: number;
    career_rbi: number;
    career_stolen_bases: number;
    career_walks: number;
    career_strikeouts: number;
    career_ba: number | string;
    career_obp: number  | string;
    career_slg: number  | string;
    career_ops: number  | string;
    career_gdp: number;
    career_hbp: number;
    career_ibb: number;
}

// Define interface for player data
export interface Higher_Lower {
    category: string;
    value: number;
    higher_lower: number | string;
    higher: boolean;
}

// Function to extract numeric attribute values into a list
function extractNumericValues(player: Player_Stats): number[] {
    const values: number[] = [];
    const keys = Object.keys(player);

    // Iterate through each key (starting from index 2) and attempt to convert its value to a number
    for (let i = 3; i < keys.length; i++) {
        const key = keys[i];
        const value = player[key as keyof Player_Stats];
        const numericValue = Number(value); // Attempt conversion to number

        if (!isNaN(numericValue)) {
            values.push(numericValue);
        }
    }

    console.log(values);
    return values;
}

function hasDecimal(num: number): boolean {
    // Convert number to string and check if it includes a decimal point
    return num.toString().includes('.');
}

function getRandomBetween(min: number, max: number): number {
    return Math.random() * (max - min) + min;
}

function getRandomBoolean(): boolean {
    return Math.random() < 0.1;
}


// Function to search for player by full_name
export function searchPlayerByName(fullName: string): Higher_Lower | null {

    const player = players.find(p => p.full_name === fullName);

    if (player !== undefined) { // Check for undefined instead of null


        const stat_labels = ["Hits", "Home Runs", "RBI", "Stolen Bases", "Walks", "Strikeouts", "Batting Avg", "On Base Pct", "Slugging", "OPS", "GDP", "Hit By Pitches", "Intentional Walks"]

        const attributeValues = extractNumericValues(player);

        const numbersOnly = attributeValues.filter(item => typeof item === 'number' && !isNaN(item));

        if (numbersOnly.length !== 13) {
            return null
        }

        let random_index: number;
        do {
            random_index = Math.floor(Math.random() * attributeValues.length);
        } while (random_index === 10);

        let higher_lower: number | string;

        const higher = getRandomBoolean();

        if (hasDecimal(numbersOnly[random_index])) {

            
            let result: number;

            if (higher) {
                let randomValue = getRandomBetween(0.90, 0.95);
                result = numbersOnly[random_index] * randomValue;

            } else {
                let randomValue = getRandomBetween(1.05, 1.10);
                result = numbersOnly[random_index] * randomValue;
            }

            const higher_lower_rounded = Math.round(result * 1000) / 1000; // Multiply by 1000, round, then divide by 1000
            higher_lower = higher_lower_rounded.toFixed(3);
        }
        else {
            let result: number;

            if (higher) {
                
                let randomValue = getRandomBetween(0.75, 0.9);
                result = numbersOnly[random_index] * randomValue;

                if (result ===  Math.round(numbersOnly[random_index])){
                    result =  numbersOnly[random_index] - 1;
                }

            } else {

                let randomValue = getRandomBetween(1.1, 1.2);
                result = numbersOnly[random_index] * randomValue;

                if (result === Math.round(numbersOnly[random_index])){
                    result =  numbersOnly[random_index] + 1;
                }
            }

            higher_lower = Math.round(result);
        }

        const ans: Higher_Lower = {
            category: stat_labels[random_index],
            value: numbersOnly[random_index],
            higher_lower: higher_lower,
            higher: higher

        }

        return ans;
    }



    else {

        return null;
    }
}
