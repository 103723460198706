import { NullablePlayer } from "./Home";
import { MyPlayer } from "./MyPlayer";
import Player from "./player";


const getWhammy = () => {

    let whammies: MyPlayer[];


    whammies = [

        // {
        //     id: "1111",
        //     image: false,
        //     rating: 0,
        //     base_rating: 0,
        //     name: "Ippei Mizuhara",
        //     special: "none",
        //     teams: [],
        //     chemistry: null
        // },
        {
            id: "1115",
            image: false,
            rating: 0,
            base_rating: 0,
            name: "Angel of Death",
            special: "none",
            teams: [],
            chemistry: null,
            double_down: null,
            double_down_value: null,
            double_down_category: null
        },
        {
            id: "1113",
            image: false,
            rating: 0,
            base_rating: 0,
            name: "low effort Jomboy tee",
            special: "none",
            teams: [],
            chemistry: null,
            double_down: null,
            double_down_value: null,
            double_down_category: null
        }
        // ,
        // {
        //     id: "1114",
        //     image: false,
        //     rating: 0,
        //     base_rating: 0,
        //     name: "Zack Hample",
        //     special: "none",
        //     teams: [],
        //     chemistry: null,
        //     double_down: false,
        //     double_down_value: null,
        //     double_down_category: null
        // }



    ]

    const randomIndex = Math.floor(Math.random() * whammies.length);
    return whammies[randomIndex]

};

export default getWhammy;
