import { NullablePlayer } from "./Home";
import { MyPlayer } from "./MyPlayer";
import getWhammy from "./getWhammy";
import Player from "./player";
import playerTeam from "./playerTeam";
import { searchPlayerByName } from "./player_stats";

const totalCards = require("./cards.json");


function higherLower(player: MyPlayer): MyPlayer {

    const higherLowerOdds: number = Math.random();

    let oddsThreshold: number;

    if (player.rating > 90) {
        oddsThreshold = 0.05
    }
    else {
        oddsThreshold = 0.15
    }

    if (higherLowerOdds < oddsThreshold) {
        const higher_lower = searchPlayerByName(player.name);

        if (higherLower === null) {
            player.double_down = null;
        } else {
            player.double_down = higher_lower!.higher;
            player.double_down_category = higher_lower!.category;
            player.double_down_value = higher_lower!.higher_lower;
        }
    }
    else {
        player.double_down = null;
    }

    return player

}

const selectPlayers = (players: NullablePlayer, pos: number, gold_odds: number, diamond_odds: number, correct: boolean, newCorrect: number, grimaceBool: boolean) => {

    console.log(diamond_odds)

    const selectedPlayers: MyPlayer[] = [];

    const whammy: number = Math.random();

    if (!correct && whammy < 0.075 && !grimaceBool) {

        let whammy = getWhammy();
        selectedPlayers.push(whammy);
        selectedPlayers.push(whammy);
        selectedPlayers.push(whammy);
    }

    const shuffleArray = (array: any[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const playerNames: string[] = players
        .filter(player => player !== null)
        .map(player => player!.name);

    const cards: MyPlayer[] = totalCards[pos.toString()]

    // const grimace: number = Math.random();

    // if (grimace < 0.01 && correct) {

    //     const grimace : MyPlayer = 
    //      {
    //         id: "0015",
    //         image: false,
    //         rating: 99,
    //         base_rating: 99,
    //         name: "GRIMACE",
    //         special: "none",
    //         teams: [],
    //         chemistry: null
    //     }

    //     selectedPlayers.push(grimace);
    //     selectedPlayers.push(grimace);
    //     selectedPlayers.push(grimace);
    // }

    // Filter the cards array to get players with ratings between 80 and 89
    const goldPlayers = cards.filter(card => card.rating >= 80 && card.rating < 90);
    const diamondPlayers = cards.filter(card => card.rating > 89);

    // Check if there are any gold players
    if (goldPlayers.length > 0 && correct && newCorrect < 9 && selectedPlayers.length < 3) {
        // Select a random gold player
        const randomIndexGold = Math.floor(Math.random() * goldPlayers.length);
        const randomIndexDiamond = Math.floor(Math.random() * diamondPlayers.length);

        const correctRandomValue: number = Math.random();

        let randomPlayer: MyPlayer

        if (correctRandomValue < diamond_odds) {

            randomPlayer = diamondPlayers[randomIndexDiamond];

        }
        else {
            randomPlayer = goldPlayers[randomIndexGold];
        }

        let finalPlayer = playerTeam(randomPlayer);
        finalPlayer.base_rating = finalPlayer.rating;

        finalPlayer = higherLower(finalPlayer);

        // finalPlayer.double_down = null;

        playerNames.push(finalPlayer.name);
        selectedPlayers.push(finalPlayer);
    }

    const tucupita: number = Math.random();

    if (tucupita < 0.043 && newCorrect < 9 && selectedPlayers.length < 3) {

        const tucupita_rating = Math.random() < 0.5 ? 0 : 99;

        selectedPlayers.push(
            {
                id: "40936",
                image: true,
                rating: tucupita_rating,
                base_rating: tucupita_rating,
                name: "Tucupita Marcano",
                special: "none",
                teams: [],
                chemistry: null,
                double_down: null,
                double_down_value: null,
                double_down_category: null
            }
        );
    }


    while (selectedPlayers.length < 3) {
        const randomValue: number = Math.random();
        let selectedPlayer: MyPlayer | undefined; // Initialize to undefined

        if (randomValue < diamond_odds) {
            // Select a player with a rating above 90
            const highRatedPlayers = cards.filter(card => card.rating >= 90);
            const filteredHighRatedPlayers = highRatedPlayers.filter(card => !selectedPlayers.includes(card));

            if (filteredHighRatedPlayers.length > 0) {
                const randomIndex = Math.floor(Math.random() * filteredHighRatedPlayers.length);
                selectedPlayer = filteredHighRatedPlayers[randomIndex];
            }

        }

        else if (randomValue < gold_odds) {
            // Select a player with a rating above 90
            const midRatedPlayers = cards.filter(card => card.rating <= 90 && card.rating >= 80);
            const filteredHighRatedPlayers = midRatedPlayers.filter(card => !selectedPlayers.includes(card));

            if (filteredHighRatedPlayers.length > 0) {
                const randomIndex = Math.floor(Math.random() * filteredHighRatedPlayers.length);
                selectedPlayer = filteredHighRatedPlayers[randomIndex];
            }

        }

        else {
            // Select any player randomly
            const lowRatedPlayers = cards.filter(card => card.rating < 80);
            const filteredLowRatedPlayers = lowRatedPlayers.filter(card => !selectedPlayers.includes(card));

            if (filteredLowRatedPlayers.length > 0) {
                const randomIndex = Math.floor(Math.random() * filteredLowRatedPlayers.length);
                selectedPlayer = filteredLowRatedPlayers[randomIndex];
            }
        }

        if (selectedPlayer && !playerNames.includes(selectedPlayer.name)) {

            let finalPlayer = playerTeam(selectedPlayer);
            finalPlayer.base_rating = finalPlayer.rating;

            finalPlayer = higherLower(finalPlayer);

            // finalPlayer.double_down = null;

            playerNames.push(finalPlayer.name);
            selectedPlayers.push(finalPlayer);
        }
    }

    // Shuffle the selected players before returning
    shuffleArray(selectedPlayers);
    return selectedPlayers;
};

export default selectPlayers;
