
import React from 'react';
const MyBanner: React.FC = () => {

    return (
        <div
            className='ad-banner'
            style={{
                minWidth: '200px',
                borderWidth: '2px',
                borderColor: 'black',
                backgroundColor: 'yellow',
                left: '50%',
                transform: 'translateX(-50%)',
                minHeight: '50px',
                position: 'absolute',
                top: '510px'
            }}
        ><h5 style={{
            marginTop: '3px',
            fontSize: '12px',
            color: 'black',
            padding: '6px'
            
        }}>Taking a break, no new questions. Check <a href = "https://x.com/ImmaculateDraft">Twitter</a> for any updates.
            </h5></div>
    );
};
export default MyBanner;
