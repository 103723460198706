import { MyPlayer } from "./MyPlayer";


const totalCards = require("./cards.json");

const getRandomPlayer = (pos: number) => {

    const cards: MyPlayer[] = totalCards[pos.toString()]
    const badPlayers = cards.filter(card => card.rating < 80);

    const randomIndex = Math.floor(Math.random() * badPlayers.length);

    let randomPlayer: MyPlayer;
    randomPlayer = badPlayers[randomIndex];

    randomPlayer.chemistry = null;
    randomPlayer.base_rating = randomPlayer.rating;

    return randomPlayer;
};

export default getRandomPlayer;
